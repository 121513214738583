import "./TheLayout.css";
import { Layout } from "antd";
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import "./ThePageHeader.css";
import TheContent from "./TheContent";
import TheFooter from "./TheFooter";

function TheLayout() {
  return (
    <Layout>
      <TheSidebar />
      <Layout style={{ height: "100vh" }}>
        <TheHeader />
        <div id="header-content-container">
          <div style={{ padding: "33px" }} />
          <TheContent />
          <TheFooter />
        </div>
      </Layout>
    </Layout>
  );
}

export default TheLayout;
