import "./TheHeader.css";
import { Layout, Avatar, Popover, Typography, Space } from "antd";
import {
  UserOutlined,
  // SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Header } = Layout;
const roleMap = {
  ADMIN: "Admin",
  EMPLOYEE: "Pegawai",
  USER: "Pengguna Umum",
};

function TheHeader() {
  const history = useHistory();

  const text = <span>{roleMap[localStorage.getItem("role")]}</span>;
  const content = (
    <Space direction="vertical">
      {/* <Typography.Link style={{ display: "block" }} disabled>
        <SettingOutlined />
        &nbsp; Pengaturan
      </Typography.Link> */}
      <Typography.Link
        style={{ display: "block" }}
        onClick={() => handleLogout()}
      >
        <LogoutOutlined />
        &nbsp; Keluar
      </Typography.Link>
    </Space>
  );

  function handleLogout() {
    localStorage.clear();
    history.push("/");
  }

  return (
    <Header id="the-header">
      <img
        src="/logo.png"
        alt="Logo Aplikasi Kerja dan Pelaporan Pemerintah Desa Padangsambian Kaja"
        className="logo"
      />
      <Popover
        className="top-right-avatar"
        placement="bottomRight"
        title={text}
        content={content}
        trigger="click"
      >
        <Avatar icon={<UserOutlined />} />
      </Popover>
      {/* <span id="main-title">Desa Padangsambian Kaja</span> */}
    </Header>
  );
}

export default TheHeader;
