import "./TheSidebar.css";
import { Layout } from "antd";
import NavAdmin from "../routes/NavAdmin";
import NavEmployee from "../routes/NavEmployee";
import NavUser from "../routes/NavUser";

const { Sider } = Layout;

function TheSidebar() {
  let UsedNav = null;
  switch (localStorage.getItem("role")) {
    case "ADMIN":
      UsedNav = NavAdmin;
      break;
    case "EMPLOYEE":
      UsedNav = NavEmployee;
      break;
    case "USER":
      UsedNav = NavUser;
      break;
    default:
      break;
  }

  return (
    <Sider
      width={220}
      id="the-sidebar"
      className="site-layout-background"
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        try {
          /* Make responsive on window change in a large device */
          if (!broken) {
            document.getElementById(
              "header-content-container"
            ).style.marginLeft = "200px";
            document.getElementById("the-footer").style.marginLeft = "20px";
          }
        } catch (error) {}
      }}
      onCollapse={(collapsed) => {
        try {
          if (/* Opened in a large device*/ window.screen.width > 1000) {
            if (collapsed) {
              document.getElementById(
                "header-content-container"
              ).style.marginLeft = "-20px";
            }
          } /* Opened in a small device*/ else {
            if (collapsed) {
              document.getElementById(
                "header-content-container"
              ).style.marginLeft = "-20px";
              document.getElementById("the-footer").style.marginLeft = "20px";
            }
          }
        } catch (error) {}
      }}
    >
      <UsedNav />
    </Sider>
  );
}

export default TheSidebar;
