import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  BarChartOutlined,
  HomeOutlined,
  BorderInnerOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

function NavUser() {
  return (
    <Menu
      // theme="dark"
      mode="inline"
      defaultSelectedKeys={["Home"]}
      style={{ height: "100%" }}
    >
      <Menu.Item key="Home" icon={<HomeOutlined />}>
        <Link to="/home">Home</Link>
      </Menu.Item>
      <SubMenu
        key="Kewilayahan"
        icon={<BorderInnerOutlined />}
        title="Kewilayahan"
      >
        <Menu.Item key="Swadaya Murni">
          <Link to="/home/kewilayahan/swadaya-murni">Swadaya Murni</Link>
        </Menu.Item>
        <Menu.Item key="Kejadian">
          <Link to="/home/kewilayahan/kejadian">Kejadian</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="E-Planning" icon={<BarChartOutlined />} title="E-Planning">
        <Menu.Item key="Proposal Usulan">
          <Link to="/home/e-planning/proposal-usulan">Proposal Usulan</Link>
        </Menu.Item>
        <Menu.Item key="RPJM">
          <Link to="/home/e-planning/rpjm">RPJM</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
}

export default NavUser;
