import { Layout, Typography } from "antd";
import "./TheFooter.css";

const { Text } = Typography;
const { Footer } = Layout;

function TheFooter() {
  return (
    <Footer id="the-footer">
      <p>
        <Text>Desa Padangsambian Kaja ©2020</Text>
        <br />
        <Text type="secondary">Created by PT. Bale Teknologi Bali</Text>
      </p>
    </Footer>
  );
}

export default TheFooter;
