import { Route, Switch } from "react-router-dom";
import "antd/dist/antd.css";
import Login from "./views/Login";
import TheLayout from "./containers/TheLayout";
import ProtectedRoute from "./routes/ProtectedRoute";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <ProtectedRoute path="/home" component={TheLayout} />
    </Switch>
  );
}

export default App;
