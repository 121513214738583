import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  BarChartOutlined,
  HomeOutlined,
  CalendarOutlined,
  BorderInnerOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

function NavEmployee() {
  return (
    <Menu
      // theme="dark"
      mode="inline"
      defaultSelectedKeys={["Home"]}
      style={{ height: "100%" }}
    >
      <Menu.Item key="Home" icon={<HomeOutlined />}>
        <Link to="/home">Home</Link>
      </Menu.Item>
      <SubMenu key="E-Kinerja" icon={<CalendarOutlined />} title="E-Kinerja">
        <Menu.Item key="Kinerja">
          <Link to="/home/e-kinerja/kinerja">Kinerja</Link>
        </Menu.Item>
        <Menu.Item key="Ijin dan Cuti">
          <Link to="/home/e-kinerja/ijin-dan-cuti">Ijin dan Cuti</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Kewilayahan"
        icon={<BorderInnerOutlined />}
        title="Kewilayahan"
      >
        <Menu.Item key="Swadaya Murni">
          <Link to="/home/kewilayahan/swadaya-murni">Swadaya Murni</Link>
        </Menu.Item>
        <Menu.Item key="Kejadian">
          <Link to="/home/kewilayahan/kejadian">Kejadian</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="E-Planning" icon={<BarChartOutlined />} title="E-Planning">
        <Menu.Item key="Proposal Usulan">
          <Link to="/home/e-planning/proposal-usulan">Proposal Usulan</Link>
        </Menu.Item>
        <Menu.Item key="RAB">
          <Link to="/home/e-planning/rab">RAB</Link>
        </Menu.Item>
        <Menu.Item key="RPJM">
          <Link to="/home/e-planning/rpjm">RPJM</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Manajemen Arsip"
        icon={<ContainerOutlined />}
        title="Manajemen Arsip"
      >
        <Menu.Item key="Data Arsip" disabled>
          <Link to="/home/manajemen-arsip/data-arsip">Data Arsip (soon)</Link>
        </Menu.Item>
        <Menu.Item key="Lihat Arsip" disabled>
          <Link to="/home/manajemen-arsip/lihat-arsip">Lihat Arsip (soon)</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
}

export default NavEmployee;
