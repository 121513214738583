import React from "react";

// ==============================  Home  ========================================
const Home = React.lazy(() => import("../views/home/Home"));

// ==============================  Kewilayahan ==================================
const SwadayaMurni = React.lazy(() =>
  import("../views/kewilayahan/swadaya-murni/SwadayaMurni")
);
const InputSwadayaMurni = React.lazy(() =>
  import(
    "../views/kewilayahan/swadaya-murni/input-swadaya-murni/InputSwadayaMurni"
  )
);
const EditSwadayaMurni = React.lazy(() =>
  import(
    "../views/kewilayahan/swadaya-murni/edit-swadaya-murni/EditSwadayaMurni"
  )
);
const Kejadian = React.lazy(() =>
  import("../views/kewilayahan/kejadian/Kejadian")
);
const InputKejadian = React.lazy(() =>
  import("../views/kewilayahan/kejadian/input-kejadian/InputKejadian")
);
const EditKejadian = React.lazy(() =>
  import("../views/kewilayahan/kejadian/edit-kejadian/EditKejadian")
);

// ==============================  E-Planning ===================================
const Rpjm = React.lazy(() => import("../views/e-planning/rpjm/Rpjm"));
const LihatSemuaRpjm = React.lazy(() =>
  import("../views/e-planning/rpjm/lihat-semua-rpjm/LihatSemuaRpjm")
);
const ProposalUsulan = React.lazy(() =>
  import("../views/e-planning/proposal-usulan/ProposalUsulan")
);
const InputProposalUsulan = React.lazy(() =>
  import(
    "../views/e-planning/proposal-usulan/input-proposal-usulan/InputProposalUsulan"
  )
);
const EditProposalUsulan = React.lazy(() =>
  import(
    "../views/e-planning/proposal-usulan/edit-proposal-usulan/EditProposalUsulan"
  )
);

// ==============================  Routing =====================================
const routesEmployee = [
  // Home
  {
    path: "/home",
    name: "Home",
    exact: true,
    component: Home,
  },
  // Kewilayahan
  {
    path: "/home/kewilayahan/swadaya-murni",
    name: "Swadaya Murni",
    exact: true,
    component: SwadayaMurni,
  },
  {
    path: "/home/kewilayahan/swadaya-murni/input-swadaya-murni",
    name: "Input Swadaya Murni",
    exact: true,
    component: InputSwadayaMurni,
  },
  {
    path: "/home/kewilayahan/swadaya-murni/edit-swadaya-murni",
    name: "Edit Swadaya Murni",
    exact: true,
    component: EditSwadayaMurni,
  },
  {
    path: "/home/kewilayahan/kejadian",
    name: "Kejadian",
    exact: true,
    component: Kejadian,
  },
  {
    path: "/home/kewilayahan/kejadian/input-kejadian",
    name: "Input Kejadian",
    exact: true,
    component: InputKejadian,
  },
  {
    path: "/home/kewilayahan/kejadian/edit-kejadian",
    name: "Edit Kejadian",
    exact: true,
    component: EditKejadian,
  },
  // E-Planning
  {
    path: "/home/e-planning/rpjm",
    name: "RPJM",
    exact: true,
    component: Rpjm,
  },
  {
    path: "/home/e-planning/rpjm/lihat-semua-rpjm",
    name: "Lihat Semua RPJM",
    exact: true,
    component: LihatSemuaRpjm,
  },
  {
    path: "/home/e-planning/proposal-usulan",
    name: "Proposal Usulan",
    exact: true,
    component: ProposalUsulan,
  },
  {
    path: "/home/e-planning/proposal-usulan/input-proposal-usulan",
    name: "Input Proposal Usulan",
    exact: true,
    component: InputProposalUsulan,
  },
  {
    path: "/home/e-planning/proposal-usulan/edit-proposal-usulan",
    name: "Edit Proposal Usulan",
    exact: true,
    component: EditProposalUsulan,
  },
];

export default routesEmployee;
