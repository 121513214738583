import React from "react";
import { Form, Input, Button, Card, Modal } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./Login.css";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 8 },
};

function Login() {
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const onFinish = (params = {}) => {
    setLoading(true);
    axios({
      url: `https://api.padangsambiankaja.desa.id/login`,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      data: params,
      method: "post",
      contentType: "application/json",
    })
      .then((res) => {
        localStorage.setItem("token", res.data.data.access_token);
        localStorage.setItem("role", res.data.data.role);
        history.push("/home");
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Modal.error({
            title: error.response.data.message,
            content: "Silakan coba kembali",
          });
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="logo-container">
        <img
          src="./logo.png"
          alt="Logo Aplikasi Kerja dan Pelaporan Desa Padangsambian Kaja"
        />
      </div>
      <div className="whole-container">
        <Card
          title="Login"
          bordered={false}
          headStyle={{
            fontSize: "20px",
            color: "white",
            fontWeight: "lighter",
            backgroundColor: "#1890ff",
          }}
        >
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: "Masukkan username" }]}
            >
              <Input placeholder="Masukkan username" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Masukkan password" }]}
            >
              <Input.Password placeholder="Masukkan password" />
            </Form.Item>

            {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item> */}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default Login;
