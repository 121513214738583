import React from "react";

// ==============================  Home  ========================================
const Home = React.lazy(() => import("../views/home/Home"));

// ==============================  E-Kinerja ====================================
const Kinerja = React.lazy(() => import("../views/e-kinerja/kinerja/Kinerja"));
const InputKinerja = React.lazy(() =>
  import("../views/e-kinerja/kinerja/input-kinerja/InputKinerja")
);
const EditKinerja = React.lazy(() =>
  import("../views/e-kinerja/kinerja/edit-kinerja/EditKinerja")
);
const IjinDanCuti = React.lazy(() =>
  import("../views/e-kinerja/ijin-dan-cuti/IjinDanCuti")
);
const InputIjinDanCuti = React.lazy(() =>
  import(
    "../views/e-kinerja/ijin-dan-cuti/input-ijin-dan-cuti/InputIjinDanCuti"
  )
);
const EditIjinDanCuti = React.lazy(() =>
  import("../views/e-kinerja/ijin-dan-cuti/edit-ijin-dan-cuti/EditIjinDanCuti")
);

// ==============================  Kewilayahan ==================================
const SwadayaMurni = React.lazy(() =>
  import("../views/kewilayahan/swadaya-murni/SwadayaMurni")
);
const InputSwadayaMurni = React.lazy(() =>
  import(
    "../views/kewilayahan/swadaya-murni/input-swadaya-murni/InputSwadayaMurni"
  )
);
const EditSwadayaMurni = React.lazy(() =>
  import(
    "../views/kewilayahan/swadaya-murni/edit-swadaya-murni/EditSwadayaMurni"
  )
);
const Kejadian = React.lazy(() =>
  import("../views/kewilayahan/kejadian/Kejadian")
);
const InputKejadian = React.lazy(() =>
  import("../views/kewilayahan/kejadian/input-kejadian/InputKejadian")
);
const EditKejadian = React.lazy(() =>
  import("../views/kewilayahan/kejadian/edit-kejadian/EditKejadian")
);

// ==============================  E-Planning ===================================
const Rpjm = React.lazy(() => import("../views/e-planning/rpjm/Rpjm"));
const InputRpjm = React.lazy(() =>
  import("../views/e-planning/rpjm/input-rpjm/InputRpjm")
);
const EditRpjm = React.lazy(() =>
  import("../views/e-planning/rpjm/edit-rpjm/EditRpjm")
);
const LihatSemuaRpjm = React.lazy(() =>
  import("../views/e-planning/rpjm/lihat-semua-rpjm/LihatSemuaRpjm")
);
const ProposalUsulan = React.lazy(() =>
  import("../views/e-planning/proposal-usulan/ProposalUsulan")
);
const InputProposalUsulan = React.lazy(() =>
  import(
    "../views/e-planning/proposal-usulan/input-proposal-usulan/InputProposalUsulan"
  )
);
const EditProposalUsulan = React.lazy(() =>
  import(
    "../views/e-planning/proposal-usulan/edit-proposal-usulan/EditProposalUsulan"
  )
);
const Rab = React.lazy(() => import("../views/e-planning/rab/Rab"));
const InputRab = React.lazy(() =>
  import("../views/e-planning/rab/input-rab/InputRab")
);
const EditRab = React.lazy(() =>
  import("../views/e-planning/rab/edit-rab/EditRab")
);

// ==============================  Manajemen Arsip ==============================
const DataArsip = React.lazy(() =>
  import("../views/manajemen-arsip/data-arsip/DataArsip")
);
const LihatArsip = React.lazy(() =>
  import("../views/manajemen-arsip/lihat-arsip/LihatArsip")
);

// ==============================  Routing =====================================
const routesEmployee = [
  // Home
  {
    path: "/home",
    name: "Home",
    exact: true,
    component: Home,
  },
  // E-Kinerja
  {
    path: "/home/e-kinerja/kinerja",
    name: "Kinerja",
    exact: true,
    component: Kinerja,
  },
  {
    path: "/home/e-kinerja/kinerja/input-kinerja",
    name: "Input Kinerja",
    exact: true,
    component: InputKinerja,
  },
  {
    path: "/home/e-kinerja/kinerja/edit-kinerja",
    name: "Edit Kinerja",
    exact: true,
    component: EditKinerja,
  },
  {
    path: "/home/e-kinerja/ijin-dan-cuti",
    name: "Ijin dan Cuti",
    exact: true,
    component: IjinDanCuti,
  },
  {
    path: "/home/e-kinerja/ijin-dan-cuti/input-ijin-dan-cuti",
    name: "Input Ijin dan Cuti",
    exact: true,
    component: InputIjinDanCuti,
  },
  {
    path: "/home/e-kinerja/ijin-dan-cuti/edit-ijin-dan-cuti",
    name: "Edit Ijin dan Cuti",
    exact: true,
    component: EditIjinDanCuti,
  },
  // Kewilayahan
  {
    path: "/home/kewilayahan/swadaya-murni",
    name: "Swadaya Murni",
    exact: true,
    component: SwadayaMurni,
  },
  {
    path: "/home/kewilayahan/swadaya-murni/input-swadaya-murni",
    name: "Input Swadaya Murni",
    exact: true,
    component: InputSwadayaMurni,
  },
  {
    path: "/home/kewilayahan/swadaya-murni/edit-swadaya-murni",
    name: "Edit Swadaya Murni",
    exact: true,
    component: EditSwadayaMurni,
  },
  {
    path: "/home/kewilayahan/kejadian",
    name: "Kejadian",
    exact: true,
    component: Kejadian,
  },
  {
    path: "/home/kewilayahan/kejadian/input-kejadian",
    name: "Input Kejadian",
    exact: true,
    component: InputKejadian,
  },
  {
    path: "/home/kewilayahan/kejadian/edit-kejadian",
    name: "Edit Kejadian",
    exact: true,
    component: EditKejadian,
  },
  // E-Planning
  {
    path: "/home/e-planning/rpjm",
    name: "RPJM",
    exact: true,
    component: Rpjm,
  },
  {
    path: "/home/e-planning/rpjm/input-rpjm",
    name: "Input RPJM",
    exact: true,
    component: InputRpjm,
  },
  {
    path: "/home/e-planning/rpjm/edit-rpjm",
    name: "Edit RPJM",
    exact: true,
    component: EditRpjm,
  },
  {
    path: "/home/e-planning/rpjm/lihat-semua-rpjm",
    name: "Lihat Semua RPJM",
    exact: true,
    component: LihatSemuaRpjm,
  },
  {
    path: "/home/e-planning/proposal-usulan",
    name: "Proposal Usulan",
    exact: true,
    component: ProposalUsulan,
  },
  {
    path: "/home/e-planning/proposal-usulan/input-proposal-usulan",
    name: "Input Proposal Usulan",
    exact: true,
    component: InputProposalUsulan,
  },
  {
    path: "/home/e-planning/proposal-usulan/edit-proposal-usulan",
    name: "Edit Proposal Usulan",
    exact: true,
    component: EditProposalUsulan,
  },
  {
    path: "/home/e-planning/rab",
    name: "RAB",
    exact: true,
    component: Rab,
  },
  {
    path: "/home/e-planning/rab/input-rab",
    name: "Input RAB",
    exact: true,
    component: InputRab,
  },
  {
    path: "/home/e-planning/rab/edit-rab",
    name: "Edit RAB",
    exact: true,
    component: EditRab,
  },
  // Manajemen Arsip
  {
    path: "/home/manajemen-arsip/data-arsip",
    name: "Data Arsip",
    exact: true,
    component: DataArsip,
  },
  {
    path: "/home/manajemen-arsip/lihat-arsip",
    name: "Lihat Arsip",
    exact: true,
    component: LihatArsip,
  },
];

export default routesEmployee;
