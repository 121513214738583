import React from "react";
import { Switch, Route } from "react-router-dom";
import routesAdmin from "../routes/routesAdmin";
import routesEmployee from "../routes/routesEmployee";
import routesUser from "../routes/routesUser";
import { Spin } from "antd";

function TheContent() {
  let usedRoutes = null;
  switch (localStorage.getItem("role")) {
    case "ADMIN":
      usedRoutes = routesAdmin;
      break;
    case "EMPLOYEE":
      usedRoutes = routesEmployee;
      break;
    case "USER":
      usedRoutes = routesUser;
      break;
    default:
      break;
  }

  return (
    <React.Suspense fallback={<Spin size="large" />}>
      <Switch>
        {usedRoutes.map((route, idx) => {
          return (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          );
        })}
      </Switch>
    </React.Suspense>
  );
}

export default TheContent;
